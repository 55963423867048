// src/components/Banner/Banner.js
import React from 'react';
import './Banner.css';
import logo from '../Navbar/ces_logo.jpg'; // Adjust the path as necessary

const Banner = () => {
  return (
    <div className="banner">
      <div className="banner-content">
        <img src={logo} alt="cmbtricee Logo" className="banner-logo" />
        {/* <h1 className="banner-title"></h1> */}
      </div>
    </div>
  );
};

export default Banner;