import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './PricingSection.css';
import axios from 'axios';
import { useCart } from '../../context/CartContext';

const PricingSection = () => {
  const [products, setProducts] = useState([]);
  const { addToCart } = useCart();

  useEffect(() => {
    axios.get('/api/products/')
      .then(response => setProducts(response.data))
      .catch(error => console.error('Error fetching products:', error));
  }, []);

  // Adjust settings based on the number of products
  const settings = {
    dots: true,
    infinite: products.length > 1, // Disable infinite if there's only one product
    speed: 500,
    slidesToShow: Math.min(products.length, 3), // Show only the number of products available
    slidesToScroll: 1,
    autoplay: products.length > 1, // Disable autoplay if there's only one product
    autoplaySpeed: 2000,
    centerMode: products.length > 1, // Disable center mode if there's only one product
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="pricing-section" id="pricing">
      <Slider {...settings}>
        {products.map(product => (
          <div key={product.id} className="product">
            <img src={product.image} alt={product.title} />
            <h2>{product.title}</h2>
            <p>Price: ${product.price}</p>
            <p>{product.description}</p>
            <button onClick={() => addToCart(product)}>Add to Cart</button>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PricingSection;