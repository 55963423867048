import React from 'react';
import './AboutUs.css';

const About = () => {
  return (
    <div className="about-container about-section" id="about">
      <h1>About Us</h1>
      <p>Welcome to Ces Moments By Tricee, where every cake is a piece of art, baked with the heart. At the core of our kitchen lies a simple yet profound philosophy: quality ingredients lead to the richest flavors. We stand proudly behind every cake we create, ensuring that each one offers a taste experience that's as delightful and memorable as the occasions they celebrate.</p>
      <p>Thank you for choosing Ces Moments By Tricee. Here's to celebrating life's sweetest moments together, one delicious cake at a time.</p>
    </div>
  );
};

export default About;
